import React, { useState } from 'react';
import { Form, FormControl, ListGroup } from 'react-bootstrap';
import './ChatbotsSearch.css';

const ChatbotsSearch = ({ availableChatbots, onSelectChatbot }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredChatbots = searchTerm.length > 0
    ? availableChatbots.filter((chatbot) =>
        chatbot.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <div className="chatbots-search">
      <Form className="w-100">
        <FormControl
          type="text"
          placeholder="Enter a name to search for chatbots"
          className="search-input"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Form>
      {filteredChatbots.length > 0 && (
        <ListGroup className="w-100 position-absolute" style={{ zIndex: 10 }}>
          {filteredChatbots.map((chatbot) => (
            <ListGroup.Item
              key={chatbot.id}
              action
              onClick={() => onSelectChatbot(chatbot)}
            >
              {chatbot.name}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  );
};

export default ChatbotsSearch;
