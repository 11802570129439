import React, { useState } from 'react';
// import { signInWithPopup, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Alert, Card } from 'react-bootstrap';
import PrivacyPolicy from '../privacypolicy/PrivacyPolicy';
import './LoginPage.css'; // Ensure you have this CSS file

function Login() {
  const [showAlert, setShowAlert] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false); // State to toggle privacy policy
  const navigate = useNavigate();

  // Initialize Google and Facebook providers
  const googleProvider = new GoogleAuthProvider();
  // const facebookProvider = new FacebookAuthProvider();

  // Google login
  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/'); // Navigate to landing page after successful login
    } catch (err) {
      console.error('Google login error:', err);
      setShowAlert(true);
    }
  };

  // Facebook login
  // const handleFacebookLogin = async () => {
  //   try {
  //     await signInWithPopup(auth, facebookProvider);
  //     navigate('/'); // Navigate to landing page after successful login
  //   } catch (err) {
  //     console.error('Facebook login error:', err);
  //     setShowAlert(true);
  //   }
  // };

  return (
    <div className="login-page-bg">
      <Container className="d-flex flex-column align-items-center justify-content-center vh-100">
        <Card className="p-4 shadow-lg w-75" style={{ maxWidth: '800px' }}>
          <h2 className="text-center mb-4 fw-bold">Luplab</h2>
          <h5 className="text-center mb-4">
            Helping influencers scale engagement & create standout content with AI
          </h5>

          {/* Embedded Demo Video */}
          {/* <div className="demo-video-container mb-4">
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/dQw4w9WgXcQ" // Replace with your actual demo video URL
              allowFullScreen
              title="Demo Video"
              style={{ width: '100%', height: '350px', borderRadius: '10px' }}
            />
          </div> */}

          {/* Google Login Button */}
          <Button
            variant="primary"
            className="mt-3 w-100 fw-bold"
            onClick={handleGoogleLogin}
            style={{ backgroundColor: '#ff6f61', borderColor: '#ff6f61' }}
          >
            Login with Google
          </Button>

          {/* Facebook Login Button */}
          {/* <Button
            variant="primary"
            className="mt-3 w-100 fw-bold btn-facebook"
            onClick={handleFacebookLogin}
          >
            Login with Facebook
          </Button> */}

          {showAlert && (
            <Alert variant="danger" className="mt-3">
              Login failed. Please try again.
            </Alert>
          )}
        </Card>
        {/* Toggle Privacy Policy Button */}
        <Button
          variant="link"
          className="mt-3"
          onClick={() => setShowPrivacyPolicy(!showPrivacyPolicy)}
          style={{ color: '#ff6f61', textDecoration: 'underline' }}
        >
          {showPrivacyPolicy ? 'Hide Privacy Policy' : 'View Privacy Policy'}
        </Button>
        {showPrivacyPolicy && (
          <div className="mt-3">
            <PrivacyPolicy />
          </div>
        )}
      </Container>
    </div>
  );
}

export default Login;
