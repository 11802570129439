import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Button, Form, Table, ProgressBar } from 'react-bootstrap';
import ConversationStarter from './ConversationStarter';
import ChatWindow from '../chat/ChatWindow';
import { saveAssistantToFirestore, updateAssistantInFirestore } from '../../utils/firestoreUtils';
import { handleFacebookLogin } from '../../utils/instagramUtils';
import { createAssistantInOpenAI, updateAssistantInOpenAI } from '../../utils/openaiUtils';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  getDoc,
  doc,
  addDoc,
  serverTimestamp,
  orderBy
} from 'firebase/firestore';

import './Create.css'; // Import the CSS file for styling

function Create() {
  const [authenticated, setAuthenticated] = useState(false);
  const [videos, setVideos] = useState([]);
  const [localVideos, setLocalVideos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [chatbotName, setChatbotName] = useState('');
  const [chatbotDescription, setChatbotDescription] = useState('');
  const [chatbotInstructions, setChatbotInstructions] = useState('');
  const [assistantCreated, setAssistantCreated] = useState(false);
  const [assistantId, setAssistantId] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [user, setUser] = useState(null);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [userAssistants, setUserAssistants] = useState([]);
  const [selectedAssistantId, setSelectedAssistantId] = useState('');
  const [prevSelectedVideos, setPrevSelectedVideos] = useState([]);
  const [assistantTranscriptions, setAssistantTranscriptions] = useState([]);

  // Initialize Firebase Auth and Firestore
  const auth = getAuth();
  const db = getFirestore();

  // Ref to store unsubscribe function for chat history listener
  const unsubscribeRef = useRef(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [auth]);

  // Fetch user's assistants
  useEffect(() => {
    if (!user) return;

    const assistantsQuery = query(
      collection(db, 'chatbots'),
      where('userID', '==', user.uid)
    );

    const unsubscribe = onSnapshot(assistantsQuery, (snapshot) => {
      const userAssistants = [];
      snapshot.forEach((docSnap) => {
        userAssistants.push({ id: docSnap.id, ...docSnap.data() });
      });
      setUserAssistants(userAssistants);
    }, (error) => {
      console.error('Error fetching user assistants:', error);
      alert('Failed to load your assistants. Please try again later.');
    });

    return () => unsubscribe();
  }, [user, db]);

  // Clean up the chat history listener when component unmounts
  useEffect(() => {
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, []);

  const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB

  const handleVideoSelect = (video) => {
    setSelectedVideos((prev) =>
      prev.includes(video) ? prev.filter((v) => v !== video) : [...prev, video]
    );
  };

  const handleLocalVideoUpload = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = [];

    files.forEach((file) => {
      const fileSizeMB = file.size / (1024 * 1024); // Convert bytes to MB
      console.log(`File "${file.name}" size: ${fileSizeMB.toFixed(2)} MB`);

      if (file.size <= MAX_FILE_SIZE) {
        validFiles.push(file);
      } else {
        alert(
          `File "${file.name}" exceeds the maximum size limit of ${
            MAX_FILE_SIZE / (1024 * 1024)
          } MB and will not be uploaded.`
        );
      }
    });

    setLocalVideos(validFiles);
    setSelectedVideos((prev) => [...prev, ...validFiles]);
  };

  const sanitizeFileName = (name) => {
    return name.replace(/[^a-z0-9.]/gi, '_').toLowerCase();
  };

  const uploadVideosToStorage = async (videos) => {
    if (!user) {
      alert('You must be logged in to upload videos.');
      return [];
    }

    const storage = getStorage();
    const filePaths = [];

    const uploadPromises = videos.map((video) => {
      const sanitizedFileName = sanitizeFileName(video.name);
      const timestamp = Date.now();
      const filePath = `videos/${user.uid}/${sanitizedFileName}_${timestamp}`;
      const storageRef = ref(storage, filePath);

      // Include contentType in metadata
      const uploadTask = uploadBytesResumable(storageRef, video, {
        contentType: video.type || 'video/mp4',
      });

      filePaths.push(filePath); // Collect file paths

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Progress handling
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done for file ${video.name}`);

            // Update progress state
            setUploadProgress((prev) => ({
              ...prev,
              [video.name]: progress,
            }));
          },
          (error) => {
            console.error('Upload error:', error);
            reject(error);
          },
          () => {
            // Upload completed successfully
            resolve();
          }
        );
      });
    });

    await Promise.all(uploadPromises);
    return filePaths;
  };

  const transcribeVideos = async (videoDescriptors) => {
    try {
      const response = await fetch('https://transcribevideo-snfypntodq-uc.a.run.app/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ videos: videoDescriptors }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to transcribe videos. Server response: ${errorText}`);
      }

      const result = await response.json();
      console.log('Transcription results:', result.transcriptions);
      return result.transcriptions;
    } catch (error) {
      console.error('Error transcribing videos:', error);
      throw error;
    }
  };

  // Function to create a chatroom
  const createChatroom = async (assistantId) => {
    try {
      // Check if a chatroom exists between the user and the assistant
      const chatroomsRef = collection(db, 'chatrooms');
      const chatroomQuery = query(
        chatroomsRef,
        where('userID', '==', user.uid),
        where('chatbotID', '==', assistantId)
      );

      const querySnapshot = await getDocs(chatroomQuery);
      let chatroomID;

      if (querySnapshot.empty) {
        // No existing chatroom, create one
        const newChatroom = {
          userID: user.uid,
          chatbotID: assistantId,
          createdAt: serverTimestamp(),
        };

        const chatroomDocRef = await addDoc(chatroomsRef, newChatroom);
        chatroomID = chatroomDocRef.id;
      } else {
        // Existing chatroom found
        chatroomID = querySnapshot.docs[0].id;
      }

      return chatroomID;
    } catch (error) {
      console.error('Error creating chatroom:', error);
      alert('Failed to create chatroom. Please try again.');
      return null;
    }
  };

  // Function to fetch chat history
  const fetchChatHistory = (chatroomID) => {
    if (!chatroomID) {
      setChatHistory([]);
      return;
    }

    if (unsubscribeRef.current) {
      // Unsubscribe from previous listener
      unsubscribeRef.current();
    }

    const messagesRef = collection(db, 'messages');
    const messagesQuery = query(
      messagesRef,
      where('chatroomID', '==', chatroomID),
      orderBy('createdAt', 'asc')
    );

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const messages = [];
      snapshot.forEach((docSnap) => {
        const data = docSnap.data();
        messages.push({
          id: docSnap.id,
          text: data.text,
          sender: data.userID ? 'user' : 'bot',
          createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
        });
      });
      setChatHistory(messages);
    }, (error) => {
      console.error('Error fetching chat history:', error);
      alert('Failed to load chat history. Please try again later.');
    });

    unsubscribeRef.current = unsubscribe;
  };

  // Handle selecting an assistant from the dropdown
  const handleAssistantSelect = async (e) => {
    const assistantId = e.target.value;
    setSelectedAssistantId(assistantId);

    if (assistantId === '') {
      // Create New Assistant selected
      // Clear the form fields
      setChatbotName('');
      setChatbotDescription('');
      setChatbotInstructions('');
      setSelectedVideos([]);
      setPrevSelectedVideos([]);
      setAssistantTranscriptions([]);
      setLocalVideos([]);
      setVideos([]);
      setAssistantCreated(false);
      setAssistantId('');
      setSelectedAssistant(null);
      setChatHistory([]);
    } else {
      // Existing assistant selected
      try {
        // Fetch assistant data from Firestore
        const assistantDocRef = doc(db, 'chatbots', assistantId);
        const assistantDocSnap = await getDoc(assistantDocRef);
        if (assistantDocSnap.exists()) {
          const assistantData = assistantDocSnap.data();
          setChatbotName(assistantData.name || '');
          setChatbotDescription(assistantData.description || '');
          setChatbotInstructions(assistantData.instructions || '');
          // Set selected videos
          setSelectedVideos(assistantData.selectedVideos || []);
          setPrevSelectedVideos(assistantData.selectedVideos || []);
          setAssistantTranscriptions(assistantData.transcriptions || []);
          // Set assistantCreated to true and assistantId
          setAssistantCreated(true);
          setAssistantId(assistantId);
          // Set selectedAssistant
          setSelectedAssistant({
            id: assistantId,
            name: assistantData.name,
            description: assistantData.description,
            instructions: assistantData.instructions,
            chatroomID: assistantData.chatroomID,
          });
          // Fetch chat history
          if (assistantData.chatroomID) {
            fetchChatHistory(assistantData.chatroomID);
          } else {
            // Create chatroom if it doesn't exist
            const chatroomID = await createChatroom(assistantId);
            setSelectedAssistant((prev) => ({
              ...prev,
              chatroomID,
            }));
            fetchChatHistory(chatroomID);
          }
        } else {
          console.error('Assistant not found in Firestore');
          alert('Assistant not found. Please try again.');
        }
      } catch (error) {
        console.error('Error fetching assistant data:', error);
        alert('Failed to fetch assistant data. Please try again.');
      }
    }
  };

  const createAssistant = async () => {
    if (!user) {
      alert('You must be logged in to create an assistant.');
      return;
    }

    const combinedInstructions = `${chatbotDescription}\n${chatbotInstructions}`;

    try {
      const videosToTranscribe = selectedVideos;

      if (videosToTranscribe.length === 0) {
        alert('No videos selected for transcription.');
        return;
      }

      // Separate local videos and Instagram videos
      const localVideoFiles = videosToTranscribe.filter((video) => video instanceof File);
      const instagramVideos = videosToTranscribe.filter((video) => video.media_url);

      // Upload local videos to Cloud Storage and get their file paths
      let localFilePaths = [];
      if (localVideoFiles.length > 0) {
        localFilePaths = await uploadVideosToStorage(localVideoFiles);
      }

      // Prepare video descriptors
      const videoDescriptors = [];

      // Add local videos with type 'storage'
      localFilePaths.forEach((filePath) => {
        videoDescriptors.push({
          type: 'storage',
          value: filePath,
        });
      });

      // Add Instagram videos with type 'url'
      instagramVideos.forEach((video) => {
        videoDescriptors.push({
          type: 'url',
          value: video.media_url,
        });
      });

      // Transcribe the videos
      const transcriptions = await transcribeVideos(videoDescriptors);
      console.log('Transcriptions:', transcriptions);

      // Create the assistant with OpenAI
      const assistant = await createAssistantInOpenAI(
        chatbotName,
        combinedInstructions,
        transcriptions
      );
      setAssistantCreated(true);
      setAssistantId(assistant.id);

      // Save assistant to Firestore
      await saveAssistantToFirestore(
        assistant.id,
        chatbotName,
        chatbotDescription,
        combinedInstructions,
        selectedVideos,
        transcriptions
      );

      // Create a chatroom
      const chatroomID = await createChatroom(assistant.id);

      // Set selectedAssistant
      setSelectedAssistant({
        id: assistant.id,
        name: chatbotName,
        description: chatbotDescription,
        instructions: combinedInstructions,
        chatroomID,
      });

      // Fetch chat history
      fetchChatHistory(chatroomID);

      // Reset the assistant selection to include the new assistant
      setSelectedAssistantId(assistant.id);
    } catch (error) {
      console.error('Error creating assistant:', error.message);
      alert(`Error creating assistant: ${error.message}`);
    }
  };

  const updateAssistant = async () => {
    setIsUpdating(true);
    const combinedInstructions = `${chatbotDescription}\n${chatbotInstructions}`;
    try {
      // Determine if there are any new videos
      const newVideos = selectedVideos.filter(
        (video) => !prevSelectedVideos.some((prevVideo) => prevVideo.id === video.id)
      );

      // Transcribe new videos if any
      let newTranscriptions = [];
      if (newVideos.length > 0) {
        // Separate local videos and Instagram videos
        const localVideoFiles = newVideos.filter((video) => video instanceof File);
        const instagramVideos = newVideos.filter((video) => video.media_url);

        // Upload local videos to Cloud Storage and get their file paths
        let localFilePaths = [];
        if (localVideoFiles.length > 0) {
          localFilePaths = await uploadVideosToStorage(localVideoFiles);
        }

        // Prepare video descriptors
        const videoDescriptors = [];

        // Add local videos with type 'storage'
        localFilePaths.forEach((filePath) => {
          videoDescriptors.push({
            type: 'storage',
            value: filePath,
          });
        });

        // Add Instagram videos with type 'url'
        instagramVideos.forEach((video) => {
          videoDescriptors.push({
            type: 'url',
            value: video.media_url,
          });
        });

        // Transcribe the new videos
        newTranscriptions = await transcribeVideos(videoDescriptors);
        console.log('New transcriptions:', newTranscriptions);
      }

      // Combine previous transcriptions with new transcriptions
      const combinedTranscriptions = [...assistantTranscriptions, ...newTranscriptions];

      // Update assistant in OpenAI
      await updateAssistantInOpenAI(
        assistantId,
        chatbotName,
        combinedInstructions,
        combinedTranscriptions
      );

      // Update assistant in Firestore
      await updateAssistantInFirestore(
        assistantId,
        chatbotName,
        chatbotDescription,
        combinedInstructions,
        selectedVideos,
        combinedTranscriptions
      );

      // Update prevSelectedVideos and assistantTranscriptions
      setPrevSelectedVideos(selectedVideos);
      setAssistantTranscriptions(combinedTranscriptions);

      setIsUpdating(false);
    } catch (error) {
      console.error('Error updating assistant:', error.message);
      alert(`Error updating assistant: ${error.message}`);
      setIsUpdating(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedAssistantId === '') {
      // Create new assistant
      createAssistant();
    } else {
      // Update existing assistant
      updateAssistant();
    }
  };

  return (
    <Container fluid className="create-page-container">
      <br></br>
      {/* Assistant Selection Dropdown */}
      <Row className="select-assistant-container">
        <Col className="select-assistant-dropdown">
          <Form.Group as={Row} controlId="formAssistantSelect">
          <Form.Label column className="select-assistant-label">
            Select Assistant
          </Form.Label>

          <Form.Control
            as="select"
            value={selectedAssistantId}
            onChange={handleAssistantSelect}
            className="custom-select"
          >
            <option value="">Create New Assistant</option>
            {userAssistants.map((assistant) => (
              <option key={assistant.id} value={assistant.id}>
                {assistant.name}
              </option>
            ))}
          </Form.Control>
          
          </Form.Group>
        </Col>
      </Row>

      {/* Main Content */}

      <Row className="main-content-row d-flex align-items-stretch flex-grow-1" style={{ gap: '20px' }}>
        {/* Create/Update Chatbot Column */}
        <Col className="create-column flex-grow-1">
          <div className="scrollable-content">
            <h1>Create Chatbot</h1>
            <p>Set the basic details for your chatbot</p>
            <Form onSubmit={handleSubmit}>

            {/* Chatbot Details */}
            <Form.Group as={Row} className="mb-3 align-items-center form-row" controlId="formChatbot">
              <Form.Label column sm="4" className="form-label-left">
                Name
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  placeholder="Enter chatbot name"
                  value={chatbotName}
                  onChange={(e) => setChatbotName(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 align-items-center form-row" controlId="formDescription">
              <Form.Label column sm="4" className="form-label-left">
                Description
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  placeholder="Add a short description of what this chatbot does"
                  value={chatbotDescription}
                  onChange={(e) => setChatbotDescription(e.target.value)}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3 align-items-center form-row" controlId="formInstructions">
              <Form.Label column sm="4" className="form-label-left">
                Instructions
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="How does this chatbot behave?"
                  value={chatbotInstructions}
                  onChange={(e) => setChatbotInstructions(e.target.value)}
                />
              </Col>
            </Form.Group>

            {/* Knowledge Section */}
            <Form.Group className="mb-3" controlId="formKnowledge">
              
              <h1>Add Knowledge</h1>
              <p>Uploaded videos will be transcribed and available to your chatbot</p>

              {/* Local Video Upload */}
              <Form.Group as={Row} className="mb-3 align-items-center form-row" controlId="formLocalVideos">
                {/* <Form.Label column sm="4" className="form-label-left">
                  Upload Videos from Your Computer
                </Form.Label> */}
                <Col sm="8">
                  <Form.Control
                    type="file"
                    accept="video/*"
                    multiple
                    onChange={handleLocalVideoUpload}
                  />
                </Col>
              </Form.Group>


              {/* Display Uploaded Videos */}
              {localVideos.length > 0 && (
                <div className="mt-3">
                  <h5>Select Local Videos to Import</h5>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Filename</th>
                        <th>Size</th>
                        <th>Select</th>
                        <th>Upload Progress</th>
                      </tr>
                    </thead>
                    <tbody>
                      {localVideos.map((video, index) => (
                        <tr key={index}>
                          <td>{video.name}</td>
                          <td>{(video.size / (1024 * 1024)).toFixed(2)} MB</td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={selectedVideos.includes(video)}
                              onChange={() => handleVideoSelect(video)}
                            />
                          </td>
                          <td>
                            {uploadProgress[video.name] ? (
                              <ProgressBar
                                now={uploadProgress[video.name]}
                                label={`${uploadProgress[video.name].toFixed(0)}%`}
                              />
                            ) : (
                              'Not uploaded'
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}

              {/* Instagram Video Fetch */}
              <Button
                variant="primary"
                onClick={async () => {
                  try {
                    await handleFacebookLogin(setAuthenticated, setVideos);
                  } catch (error) {
                    console.error('Error during Facebook login:', error);
                    alert('Error during Facebook login. Please try again.');
                  }
                }}
                className="mt-3"
              >
                {authenticated ? 'Fetch Instagram Videos' : 'Upload from Instagram'}
              </Button>

              {/* Display Instagram Videos */}
              {videos.length > 0 && (
                <div className="mt-3">
                  <h5>Select Instagram Videos to Import</h5>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Thumbnail</th>
                        <th>Label</th>
                        <th>Timestamp</th>
                        <th>Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {videos.map((video) => (
                        <tr key={video.id}>
                          <td>
                            <img
                              src={video.thumbnail_url}
                              alt="Thumbnail"
                              style={{ width: '100px', height: 'auto' }}
                            />
                          </td>
                          <td>{video.caption || video.media_url}</td>
                          <td>{new Date(video.timestamp).toLocaleString()}</td>
                          <td>
                            <Form.Check
                              type="checkbox"
                              checked={selectedVideos.includes(video)}
                              onChange={() => handleVideoSelect(video)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </Form.Group>

            {/* Create or Update Button */}
            {selectedAssistantId === '' ? (
                <Button variant="secondary" type="submit">
                  Create Assistant
                </Button>
              ) : (
                <Button variant="success" className="mt-3" onClick={updateAssistant} disabled={isUpdating}>
                  {isUpdating ? 'Updating...' : 'Update Assistant'}
                </Button>

              )}
            </Form>
          </div>
        </Col>

        <Col className="chat-column flex-grow-1">
          
          <div className="scrollable-content">
            <h1>Test Your Assistant</h1>
            <p>Once you create or select an existing chatbot, test it here</p>
            {assistantCreated && selectedAssistant && (
              <ChatWindow
                assistant={selectedAssistant}
                chatHistory={chatHistory}
                setChatHistory={setChatHistory}
              />
            )}
          </div>
          
        </Col>
      </Row>

    </Container>
  );
}



export default Create;