// src/components/chat/Chat.js
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AssistantsSidebar from './AssistantsSidebar';
import ChatbotsSearch from './ChatbotsSearch';
import ChatWindow from './ChatWindow';
import { AuthContext } from '../authentication/AuthContext';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  doc,
  getDoc,
  addDoc,
  serverTimestamp,
  orderBy
} from 'firebase/firestore';
import './Chat.css';

const Chat = () => {
  const { user } = useContext(AuthContext);
  const db = getFirestore();
  const [assistants, setAssistants] = useState([]);
  const [availableChatbots, setAvailableChatbots] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);

  // Fetch user's chatrooms and associated chatbots
  useEffect(() => {
    if (!user) return;
  
    const chatroomsQuery = query(
      collection(db, 'chatrooms'),
      where('userID', '==', user.uid)
    );
  
    const unsubscribe = onSnapshot(
      chatroomsQuery,
      async (snapshot) => {
        if (snapshot.empty || !snapshot.docs) {
          setAssistants([]);
          return;
        }
  
        const assistantsData = [];
  
        // Fetch chatbot details for each chatroom
        const chatbotPromises = snapshot.docs.map(async (docSnap) => {
          const chatroomData = docSnap.data();
          const chatbotRef = doc(db, 'chatbots', chatroomData.chatbotID);
          const chatbotSnap = await getDoc(chatbotRef);
          if (chatbotSnap.exists()) {
            const chatbotData = chatbotSnap.data();
            return { id: chatbotSnap.id, ...chatbotData, chatroomID: docSnap.id };
          }
          return null;
        });
  
        const chatbots = await Promise.all(chatbotPromises);
        chatbots.forEach((chatbot) => {
          if (chatbot) {
            assistantsData.push(chatbot);
          }
        });
  
        setAssistants(assistantsData);
      },
      (error) => {
        console.error('Error fetching chatrooms:', error);
        alert('Failed to load chatrooms. Please try again later.');
      }
    );
  
    return () => unsubscribe(); // Properly unsubscribe when the component unmounts
  }, [user, db]);
  
  

  // Fetch all available (published) chatbots
  useEffect(() => {
    if (!user) return;

    const chatbotsQuery = query(
      collection(db, 'chatbots'),
      where('published', '==', true)
    );

    const unsubscribe = onSnapshot(chatbotsQuery, (snapshot) => {
      const chatbots = [];
      snapshot.forEach((docSnap) => {
        chatbots.push({ id: docSnap.id, ...docSnap.data() });
      });
      setAvailableChatbots(chatbots);
    }, (error) => {
      console.error('Error fetching available chatbots:', error);
      alert('Failed to load available chatbots. Please try again later.');
    });

    return () => unsubscribe();
  }, [user, db]);

  // Handle selecting an assistant from the sidebar
  const handleSelectAssistant = (assistant) => {
    setSelectedAssistant(assistant);
    // Fetch chat history for the selected assistant
    fetchChatHistory(assistant.chatroomID);
  };

  // Handle selecting a chatbot from the search
  const handleSelectChatbot = async (chatbot) => {
    try {
      // Check if a chatroom exists between the user and the chatbot
      const chatroomsRef = collection(db, 'chatrooms');
      const chatroomQuery = query(
        chatroomsRef,
        where('userID', '==', user.uid),
        where('chatbotID', '==', chatbot.id)
      );

      const querySnapshot = await getDocs(chatroomQuery);
      let chatroomID;

      if (querySnapshot.empty) {
        // No existing chatroom, create one
        const newChatroom = {
          userID: user.uid,
          chatbotID: chatbot.id,
          createdAt: serverTimestamp(),
        };

        const chatroomDocRef = await addDoc(chatroomsRef, newChatroom);
        chatroomID = chatroomDocRef.id;
      } else {
        // Existing chatroom found
        chatroomID = querySnapshot.docs[0].id;
      }

      // Fetch chat history
      fetchChatHistory(chatroomID);

      // Set the selected assistant with chatroomID
      setSelectedAssistant({ id: chatbot.id, ...chatbot, chatroomID });
    } catch (error) {
      console.error('Error selecting chatbot:', error);
      alert('Failed to select chatbot. Please try again.');
    }
  };

  // Function to fetch chat history for a given chatroomID
  const fetchChatHistory = (chatroomID) => {
    if (!chatroomID) {
      setChatHistory([]);
      return;
    }
  
    const messagesRef = collection(db, 'messages');
    const messagesQuery = query(
      messagesRef,
      where('chatroomID', '==', chatroomID),
      orderBy('createdAt', 'asc')
    );
  
    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      if (!snapshot.docs) {
        setChatHistory([]);
        return;
      }
  
      const messages = [];
      snapshot.forEach((docSnap) => {
        const data = docSnap.data();
        messages.push({
          id: docSnap.id,
          text: data.text,
          sender: data.userID ? 'user' : 'bot',
          createdAt: data.createdAt ? data.createdAt.toDate() : new Date(),
        });
      });
      setChatHistory(messages);
    }, (error) => {
      console.error('Error fetching chat history:', error);
      alert('Failed to load chat history. Please try again later.');
    });
  
    // Cleanup previous listeners if any
    return () => unsubscribe();
  };
  

  return (
    <>
      {/* Main Content */}
      <Container fluid className="chat-container">
        <Row style={{ height: '100%' }}>
          {/* Assistants Sidebar */}
          <Col xs={12} md={3} lg={2} className="p-0">
            <AssistantsSidebar
              assistants={assistants}
              onSelectAssistant={handleSelectAssistant}
            />
          </Col>

          {/* Chat Area */}
          <Col xs={12} md={9} lg={10} className="p-0 d-flex flex-column">
            {/* Chatbots Search Bar */}
            <ChatbotsSearch
              availableChatbots={availableChatbots}
              onSelectChatbot={handleSelectChatbot}
            />

            {/* Chat Window */}
            <ChatWindow
              assistant={selectedAssistant}
              chatHistory={chatHistory}
              setChatHistory={setChatHistory}
            />
          </Col>
        </Row>
      </Container>

    </>
  );
};

export default Chat;
