import React, { useContext } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AuthContext } from '../authentication/AuthContext'; // Import the AuthContext
import LogoutButton from '../authentication/LogoutButton';  // Import the LogoutButton component
import './Header.css'; // Import the custom CSS for header

function Header() {
  const { user } = useContext(AuthContext); // Get the authenticated user

  return (
    <Navbar expand="lg" className="custom-navbar">
      <Container fluid>
        <Navbar.Brand href="/">Luplab</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>Chat</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/create">
              <Nav.Link>Create</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav className="ms-auto">
            {user && (
              // If the user is logged in, show the email and LogoutButton component
              <>
                <Nav.Link disabled>{user.email}</Nav.Link>
                <LogoutButton /> {/* Use the LogoutButton component here */}
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
