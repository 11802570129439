// instagramUtils.js

export const fetchInstagramBusinessAccountId = async (accessToken) => {
    try {
      const response = await fetch(`https://graph.facebook.com/me/accounts?access_token=${accessToken}`);
      const data = await response.json();
      const pageId = data.data[0]?.id;
      if (pageId) {
        const igResponse = await fetch(`https://graph.facebook.com/${pageId}?fields=instagram_business_account&access_token=${accessToken}`);
        const igData = await igResponse.json();
        const instagramBusinessId = igData.instagram_business_account?.id;
        return instagramBusinessId;
      }
      return null;
    } catch (error) {
      console.error('Error fetching Instagram Business Account ID:', error);
      return null;
    }
  };
  
  export const fetchInstagramVideos = async (accessToken, userId) => {
    try {
      const response = await fetch('https://getinstagramvideos-snfypntodq-uc.a.run.app', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, accessToken }),
        credentials: 'include'
      });
  
      const data = await response.json();
      const mediaItems = data.mediaData.data;
  
      return mediaItems.filter(item => item.media_type.trim() === 'VIDEO')
        .map(item => ({
          id: item.id,
          media_url: item.media_url,
          caption: item.caption,
          thumbnail_url: item.thumbnail_url,
          permalink: item.permalink,
          timestamp: item.timestamp,
          username: item.username
        }));
    } catch (error) {
      console.error('Error fetching videos:', error.message);
      throw error;
    }
  };
  
  export const handleFacebookLogin = (setAuthenticated, setVideos) => {
    return new Promise((resolve, reject) => {
      window.FB.login((response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          
          fetchInstagramBusinessAccountId(accessToken)
            .then(instagramBusinessId => {
              if (instagramBusinessId) {
                return fetchInstagramVideos(accessToken, instagramBusinessId);
              } else {
                throw new Error('Instagram Business ID not found');
              }
            })
            .then(videos => {
              setVideos(videos);
              setAuthenticated(true);
              resolve();  // Successfully completed
            })
            .catch(error => {
              console.error('Error during Facebook login:', error);
              reject(error);  // Reject the promise on error
            });
        } else {
          console.log('User cancelled login or did not fully authorize.');
          reject('Login cancelled or not authorized');
        }
      }, { scope: 'public_profile,instagram_basic,instagram_manage_insights,pages_show_list' });
    });
  };
  