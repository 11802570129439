import React, { useState, useContext, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import MessageList from './MessageList';
import ChatInput from './ChatInput';
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { AuthContext } from '../authentication/AuthContext';
import './ChatWindow.css';
import { createThread, addMessage, createRun } from '../../utils/openaiUtils';

const ChatWindow = ({ assistant, chatHistory, setChatHistory }) => {
  const { user } = useContext(AuthContext);
  const db = getFirestore();
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [threadId, setThreadId] = useState(null);

  // Scroll to the bottom when chat history updates
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  // Initialize thread when assistant changes
  useEffect(() => {
    const initThread = async () => {
      // Ensure assistant is not null and has a valid chatroomID
      if (!assistant || !assistant.chatroomID) {
        console.warn('Warning: Assistant or chatroom ID is not defined.');
        return;
      }

      let threadID = assistant.threadID;

      if (!threadID) {
        try {
          // Create a new thread if one doesn't exist
          const thread = await createThread();
          if (thread && thread.id) {
            threadID = thread.id;
            setThreadId(threadID);

            // Update assistant in Firestore to store the threadID
            const assistantRef = doc(db, 'chatrooms', assistant.chatroomID);
            await updateDoc(assistantRef, { threadID });

            // Update the assistant object to include threadID
            assistant.threadID = threadID;
          } else {
            console.error('Error: Failed to create a thread.');
          }
        } catch (error) {
          console.error('Error creating thread:', error);
        }
      } else {
        setThreadId(threadID);
      }
    };

    // Only run initThread if the assistant is defined and has the required fields
    if (assistant && assistant.chatroomID) {
      initThread();
    }
  }, [assistant, db]);

  // Handle sending a message
  const handleSendMessage = async (messageText) => {
    if (messageText.trim() === '' || !assistant || !threadId) return;

    // Append user's message to chat history
    const newUserMessage = {
      text: messageText,
      sender: 'user',
      createdAt: new Date(),
      humanMessage: true,
      chatbotID: assistant.id,
      chatroomID: assistant.chatroomID,
      userID: user?.uid || 'unknown-user',
    };
    setChatHistory([...chatHistory, newUserMessage]);

    // Add user message to Firestore
    try {
      const messagesRef = collection(db, 'messages');
      await addDoc(messagesRef, {
        chatbotID: assistant.id,
        chatroomID: assistant.chatroomID,
        createdAt: serverTimestamp(),
        humanMessage: true,
        text: messageText,
        userID: user?.uid || 'unknown-user',
      });

      // Add user's message to the OpenAI thread
      await addMessage(threadId, {
        role: 'user',
        content: messageText,
      });

      // Run the assistant to get a response
      const assistantResponse = await createRun(threadId, assistant.id);

      // Update the chat history with the assistant's response
      if (assistantResponse) {
        const botMessage = {
          text: assistantResponse,
          sender: 'bot',
          createdAt: new Date(),
          humanMessage: false,
          chatbotID: assistant.id,
          chatroomID: assistant.chatroomID,
          userID: null,
        };
        setChatHistory((prevMessages) => [...prevMessages, botMessage]);

        // Save the assistant's response to Firestore
        await addDoc(messagesRef, {
          chatbotID: assistant.id,
          chatroomID: assistant.chatroomID,
          createdAt: serverTimestamp(),
          humanMessage: false,
          text: assistantResponse,
          userID: null,
        });
      } else {
        console.error('Error: Assistant response is undefined.');
      }
    } catch (err) {
      console.error('Error sending message:', err);
    }

    setMessage('');
  };

  if (!assistant) {
    return (
      <Container className="chat-window no-assistant p-3">
        <h5>Select or create an assistant to start chatting.</h5>
      </Container>
    );
  }

  return (
    <Container className="chat-window border rounded">
      <Row className="chat-header text-white p-2">
        <Col>
          <h5 className="text-center">{assistant.name}</h5>
        </Col>
      </Row>
      <Row
        className="chat-body p-3"
        style={{ flex: '1', overflowY: 'auto', height: '60vh' }}
      >
        <MessageList messages={chatHistory} />
        <div ref={messagesEndRef} />
      </Row>
      <Row className="chat-input-container p-2">
        <ChatInput
          message={message}
          setMessage={setMessage}
          onSend={() => handleSendMessage(message)}
        />
      </Row>
    </Container>
  );
};

export default ChatWindow;
