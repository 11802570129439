import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Chat from './components/chat/Chat';
import Create from './components/create/Create';
import Header from './components/header/Header';
import Footer from './components/Footer';
import Login from './components/authentication/Login';
import PrivateRoute from './components/authentication/PrivateRoute';
import { AuthProvider, AuthContext } from './components/authentication/AuthContext';
import './App.css'; // Import global styles

function App() {
  return (
    <AuthProvider>
      <div className="App">
        {/* Header */}
        <Header />

        {/* Main Content */}
        <main className="App-content">
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/" element={
              <RequireAuth>
                <Chat />
              </RequireAuth>
            } />

            {/* Private Routes */}
            <Route path="/create" element={<PrivateRoute element={Create} />} />
          </Routes>
        </main>

        {/* Footer */}
        <Footer />
      </div>
    </AuthProvider>
  );
}

// Wrapper to protect routes that require authentication
const RequireAuth = ({ children }) => {
  const { user, loading } = React.useContext(AuthContext);

  if (loading) {
    return <p>Loading...</p>;
  }

  return user ? children : <Navigate to="/login" />;
};

export default App;
