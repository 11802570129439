// src/components/chat/AssistantsSidebar.js
import React from 'react';
import { ListGroup } from 'react-bootstrap';
import './AssistantsSidebar.css';

const AssistantsSidebar = ({ assistants, onSelectAssistant }) => {
  return (
    <div className="assistants-sidebar">
      <h5 className="mt-3 mb-3">Your Chatbots</h5>
      <ListGroup variant="flush">
        {assistants.map((assistant) => (
          <ListGroup.Item
            key={assistant.id}
            action
            onClick={() => onSelectAssistant(assistant)}
          >
            {assistant.name}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
};

export default AssistantsSidebar;
