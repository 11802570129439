// import React, { useState } from 'react';
// import { InputGroup, FormControl, Button } from 'react-bootstrap';

// const ChatInput = ({ onSend }) => {
//   const [inputValue, setInputValue] = useState('');

//   // Handle input change
//   const handleChange = (e) => {
//     setInputValue(e.target.value);
//   };

//   // Handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     onSend(inputValue);
//     setInputValue(''); // Clear the input field
//   };

//   return (
//     <form onSubmit={handleSubmit} style={{ width: '100%' }}>
//       <InputGroup>
//         <FormControl
//           placeholder="Type a message..."
//           value={inputValue}
//           onChange={handleChange}
//         />
//         <Button type="submit" variant="primary">
//           Send
//         </Button>
//       </InputGroup>
//     </form>
//   );
// };

// export default ChatInput;


// src/components/chat/ChatInput.js
import React from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import './ChatInput.css';

const ChatInput = ({ message, setMessage, onSend }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onSend();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="Type your message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button variant="primary" type="submit">
          Send
        </Button>
      </InputGroup>
    </Form>
  );
};

export default ChatInput;

